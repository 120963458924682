/* eslint-disable max-len */
import {
  useMemo, useState, useEffect, useContext,
} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useScreenClass} from 'react-grid-system';
import {useHistory, useLocation} from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import {Col, Row} from '../../components/StyledGridSystem';
import _theme from '../../styles/theme';
import FlexBox, {FlexGrow} from '../../components/FlexBox';
import Button from '../../components/Button';
import Status from '../../types/enum/Status';
import Icon from '../../components/Icon';
import {StyledText, TextSize, TextStatus} from '../../components/Text';
import {StudentContext} from '../../context/StudentBehalfContext';
import TabGroup, {TabItem} from '../../components/TabGroup';
import Card from '../../components/Card';
import Table from '../../components/Table';
import NullStateTR from '../../components/NullStateTr';
import BasicUserInfo from '../../components/BasicUserInfo';
import PersonalInfo from '../../components/PersonalInfo';
import StudentInfo from '../../components/StudentInfo';
import {UserContext} from '../../context/UserContext';
import Alert from '../../components/Alert';
import ConfirmGradModal from '../../components/ConfirmGradModal';
import LocalAddress from '../../components/LocalAddress';
import HomeAddress from '../../components/HomeAddress';
import FirstEmergencyContact from '../../components/FirstEmergencyContact';
import SecondEmergencyContact from '../../components/SecondEmergencyContact';
import {date} from "yup";
import student from "../../types/Student";

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const LeftAlign = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Header = styled.h2<{ isMobile?: boolean }>`
  font-size: ${({isMobile}) => (isMobile ? '1.25rem' : '1.75rem')};
  margin-bottom: 0;
`;
const SubHeader = styled.div<{ isMobile?: boolean }>`
  font-size: ${({isMobile}) => (isMobile ? '0.75rem' : '1rem')};
  display: flex;
  justify-content: flex-start;
`;
const Greytext = styled.div`
  color: ${({theme}) => theme.color.textSecondary} !important;
  white-space: nowrap;
`;

const RemoveCourse = styled.div`
  color: ${({theme}) => theme.color.primary};
`;
type Gender = 'male' | 'female' | 'non-binary';
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';

/* eslint-disable camelcase */
interface User {
  student_id: string;
  user_id: string;
  personal_email: string;
  last_name: string;
  middle_name: string;
  first_name: string;
  canvas_id: string;
  gender: Gender | string;
  phone_number: string;
  is_active: Boolean;
  profile_url: string;
  cmkl_email: string;
  cmkl_id: string;
  birthdate: string;
  nationality: string;
  expected_graduation_date: string;
  enrolled_date: string;
  activity_type: {
    id: number;
    name: string;
  };
  diploma_name: string;
  local_address: {
    id: string;
    address: string;
    city: string;
    region: string;
    country: string;
    zip_code: string;
  };
  home_address: {
    id: string;
    address: string;
    city: string;
    region: string;
    country: string;
    zip_code: string;
  };
  first_emergency_contact: {
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    relationship: string;
    phone_number: string;
    personal_email: string;
  };
  second_emergency_contact: {
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    relationship: string;
    phone_number: string;
    personal_email: string;
  };
  program: {
    id: string;
    name: string;
  };
  honor: {
    id: string;
    honor_name: string;
  };
  expected_graduation_term: {
    id: string;
    name: string;
  };
}

interface Course {
  CourseName: string;
  TermName: string;
}

interface Student {
  FirstName: string;
  LastName: string;
}

interface User {
  FirstName: string;
  LastName: string;
}

interface ActivityInfo {
  activity_type: string;
  course: Course;
  created_at: string;
  id: string;
  object: string;
  object_id: string;
  student: Student;
  subject: string;
  subject_id: string;
  token_role: string;
  token_user_id: string;
  user: User;
}

interface FinancialInfo {
  name: string;
  account_number: string;
  total_cost: number;
  account_balance_accrued: number;
  total_income: number;
  current_date: string;
}

const showFinanceData = process.env.REACT_APP_SHOW_FINANCE_DATA === 'true';

function PersonalInfoPage(this: any, props: any) {
  const location = useLocation();
  const {userId} = props.location.state || {};
  const {role} = props.location.state || {};
  const studentBehalf = useContext(StudentContext);
  const {isBehalf} = useContext(StudentContext);
  const currentUser = useContext(UserContext).user;
  const test = [{name: 'Personal Info', link: '/personalInfo'}];
  const [tab, setTab] = useState(0);
  const [verified, setVerified] = useState<boolean>(false);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
      () => ['xs', 'sm'].includes(screenClass),
      [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [basicUserInfo, setBasicUserInfo] = useState({
    user_id: '',
    student_id: '',
    last_name: '',
    middle_name: '',
    first_name: '',
    canvas_id: '',
    cmkl_email: '',
    expected_graduation_date: '',
    diploma_name: '',
    profile_url: '',
    program: {
      id: '',
      name: '',
    },
  });
  const [personalInfo, setPersonalInfo] = useState({
    user_id: '',
    personal_email: '',
    gender: '',
    phone_number: '',
    nationality: '',
    birthdate: '',
  });
  const [firstEmergencyContact, setFirstEmergencyContact] = useState({
    user_id: '',
    student_id: '',
    first_emergency_contact: {
      id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      relationship: '',
      phone_number: '',
      personal_email: '',
    },
  });
  const [secondEmergencyContact, setSecondEmergencyContact] = useState({
    user_id: '',
    student_id: '',
    second_emergency_contact: {
      id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      relationship: '',
      phone_number: '',
      personal_email: '',
    },
  });
  const [localAddress, setLocalAddress] = useState({
    user_id: '',
    address: {
      id: '',
      address: '',
      city: '',
      region: '',
      country: '',
      zip_code: '',
    },
  });
  const [homeAddress, setHomeAddress] = useState({
    user_id: '',
    address: {
      id: '',
      address: '',
      city: '',
      region: '',
      country: '',
      zip_code: '',
    },
  });
  const [studentDetail, setStudentDetail] = useState({
    user_id: '',
    student_id: '',
    cmkl_id: '',
    activity_type: {
      id: 0,
      name: '',
    },
    expected_graduation_date: '',
    enrolled_date: '',
    program: {
      id: '',
      name: '',
    },
    honor: {
      id: '',
      honor_name: '',
    },
    expected_graduation_term: {
      id: '',
      name: '',
    },
  });

  const [activityInfo, setActivityInfo] = useState<ActivityInfo[]>([]);
  const [financialData, setFinancialData] = useState<FinancialInfo>();
  const history = useHistory();
  const viewStudentBehalf = (data: any) => {
    if (data) {
      window.localStorage.setItem('behalfStudent', JSON.stringify(data));
      studentBehalf.setCurrentStudent(data);
    }
  };
  const monthDiff = (date1: any, date2: any) => {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months >= 0 && months <= 3;
  };

  function concat(first: string, middle: string, last: string) {
    if (middle !== '') {
      return `${first} ${middle} ${last}`;
    }
    return `${first} ${last}`;
  }

  const verify = () => {
    setIsConfirmModalOpen(true);
  };
  useEffect(() => {
    // fetchStage();
    fetchUserInfo();
  }, [userId, location]);

  useEffect(() => {
    fetchActivityInfo();
    fetchFinancialData();
  }, [basicUserInfo]);
  const isCurrentUser = () => currentUser.user_id === userId;
  const fetchUserInfo = () => {
    setLoading(true);
    axios
        .get(`/api/v1/users/${userId}`)
        .then((res) => {
          if (res.data.data.user) {
            setVerified(res.data.data.user.information_confirm);
            mapUserData(
                res.data.data.user,
                res.data.data.user.information_confirm,
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  };

  let returnStudentId = () => {
    return basicUserInfo.user_id;
  }
  const fetchFinancialData = () => {
    var currentUserId = returnStudentId()
    setLoading(true);
    axios
        .get(`/api/v1/students/${currentUserId}/finances_airtable`)
        .then(async (res) => {
          if (res.data.data.financeinfo) {
            setFinancialData(res.data.data.financeinfo);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  };
  const fetchActivityInfo = () => {
    var currentUserId = basicUserInfo.user_id

    setLoading(true);
    axios
        .get(`/api/v1/activity-logs/${currentUserId}`)
        .then(async (res) => {
          if (res.data.data) {
            setActivityInfo(res.data.data);

          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  };
  const mapUserData = (user: User, verify: boolean) => {
    setBasicUserInfo({
      ...basicUserInfo,
      user_id: user.user_id,
      student_id: user.student_id,
      last_name: user.last_name,
      middle_name: user.middle_name,
      first_name: user.first_name,
      canvas_id: user.canvas_id,
      cmkl_email: user.cmkl_email,
      profile_url: user.profile_url,
      expected_graduation_date: user.expected_graduation_date,
      program: user.program,
      diploma_name: verify
          ? user.diploma_name
          : concat(user.first_name, user.middle_name, user.last_name),
    });
    setLocalAddress({
      ...localAddress,
      user_id: user.user_id,
      address: {
        id: user.local_address?.id,
        address: user.local_address?.address,
        city: user.local_address?.city,
        region: user.local_address?.region,
        country: user.local_address?.country,
        zip_code: user.local_address?.zip_code,
      },
    });
    setHomeAddress({
      ...homeAddress,
      user_id: user.user_id,
      address: {
        id: user.home_address?.id,
        address: user.home_address?.address,
        city: user.home_address?.city,
        region: user.home_address?.region,
        country: user.home_address?.country,
        zip_code: user.home_address?.zip_code,
      },
    });
    setPersonalInfo({
      ...personalInfo,
      user_id: user?.user_id,
      nationality: user?.nationality,
      birthdate: user?.birthdate,
      personal_email: user?.personal_email,
      gender: user?.gender,
      phone_number: user?.phone_number,
    });
    setFirstEmergencyContact({
      ...firstEmergencyContact,
      user_id: user?.user_id,
      student_id: user?.student_id,
      first_emergency_contact: {
        id: user.first_emergency_contact?.id,
        first_name: user.first_emergency_contact?.first_name,
        middle_name: user.first_emergency_contact?.middle_name,
        last_name: user.first_emergency_contact?.last_name,
        relationship: user.first_emergency_contact?.relationship,
        phone_number: user.first_emergency_contact?.phone_number,
        personal_email: user.first_emergency_contact?.personal_email,
      },
    });
    setSecondEmergencyContact({
      ...secondEmergencyContact,
      user_id: user?.user_id,
      student_id: user?.student_id,
      second_emergency_contact: {
        id: user.second_emergency_contact?.id,
        first_name: user.second_emergency_contact?.first_name,
        middle_name: user.second_emergency_contact?.middle_name,
        last_name: user.second_emergency_contact?.last_name,
        relationship: user.second_emergency_contact?.relationship,
        phone_number: user.second_emergency_contact?.phone_number,
        personal_email: user.second_emergency_contact?.personal_email,
      },
    });
    setStudentDetail({
      ...studentDetail,
      cmkl_id: user?.cmkl_id,
      user_id: user?.user_id,
      student_id: user?.student_id,
      expected_graduation_date: user?.expected_graduation_date,
      enrolled_date: user?.enrolled_date,
      activity_type: {
        id: user?.activity_type.id,
        name: user?.activity_type.name,
      },
      program: {
        id: user?.program.id,
        name: user?.program.name,
      },
      honor: {
        id: user?.honor.id,
        honor_name: user?.honor.honor_name,
      },
      expected_graduation_term: {
        id: user?.expected_graduation_term.id,
        name: user?.expected_graduation_term.name,
      },
    });
  };
  const confirmGraduation = (diploma: any) => {
    setLoading(true);
    axios
        .put(`/api/v1/students/${basicUserInfo.student_id}/confirm`, {
          diploma_name: diploma,
        })
        .then(() => {
          setIsConfirmModalOpen(false);
          const path = '/personalInfo';
          // history.push(path);
          history.push({
            pathname: path,
            state: {
              userId: basicUserInfo.user_id,
              role:
                  currentUser.role && currentUser.role !== ''
                      ? currentUser.role
                      : 'student',
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
  };

  return (
      <>
        <ConfirmGradModal
            isOpen={isConfirmModalOpen}
            setOpen={setIsConfirmModalOpen}
            confirmGraduation={confirmGraduation}
            diplomaName={basicUserInfo.diploma_name}
        />
        <Row>
          <Col>
            <Breadcrumb path={test}/>
          </Col>
        </Row>
        {((currentUser.role === 'admin' && isBehalf)
                || currentUser.role === 'student')
            && monthDiff(
                new Date(),
                new Date(basicUserInfo.expected_graduation_date),
            ) && (
                <FlexBox style={{marginBottom: '16px'}}>
                  <FlexGrow>
                    <Alert
                        style={{
                          backgroundColor: '#e3f1fd',
                          color: '#1c5f9d',
                          border: '2px solid #1c5f9d',
                        }}>
                      {!isMobile && (
                          <FlexBox>
                            <FlexGrow style={{flexGrow: 0, marginRight: '16px'}}>
                              <Icon name="alert-octagon"/>
                            </FlexGrow>
                            <FlexGrow grow={3}>
                              <b>
                                {' '}
                                We are currently preparing the information for
                                graduation process,
                              </b>
                              please click “Verify” to review your personal information
                            </FlexGrow>
                            <FlexGrow style={{flexGrow: 0, whiteSpace: 'nowrap'}}>
                              {verified ? (
                                  <StyledText
                                      textSize={TextSize.default}
                                      status={TextStatus.success}>
                                    <Icon
                                        mr={1}
                                        style={{verticalAlign: 'middle'}}
                                        name="verified"
                                    />
                                    Verified
                                  </StyledText>
                              ) : (
                                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                  <a
                                      style={{color: '#1c5f9d', cursor: 'pointer'}}
                                      onClick={() => {
                                        verify();
                                      }}>
                                    Verify{' '}
                                    <Icon
                                        mr={1}
                                        style={{verticalAlign: 'middle'}}
                                        name="chevron-right-s"
                                    />
                                  </a>
                              )}
                            </FlexGrow>
                          </FlexBox>
                      )}
                      {isMobile && (
                          <div>
                            <div>
                              <Icon name="alert-octagon"/>
                              <b>
                                {' '}
                                We are currently preparing the information for
                                graduation process,{' '}
                              </b>
                              please click “Verify” to review your personal information
                            </div>
                            {verified ? (
                                <StyledText
                                    textSize={TextSize.default}
                                    status={TextStatus.success}>
                                  <Icon
                                      mr={1}
                                      style={{verticalAlign: 'middle'}}
                                      name="verified"
                                  />
                                  Verified
                                </StyledText>
                            ) : (
                                <a style={{color: '#1c5f9d'}}>
                                  Verify <Icon mr={1} name="chevron-right-s"/>
                                </a>
                            )}
                          </div>
                      )}
                    </Alert>
                  </FlexGrow>
                </FlexBox>
            )}
        <Row justify="between">
          <Col>
            <FlexBox alignItems="flex-start">
              <FlexGrow>
                <Header isMobile={isMobile}>
                  {basicUserInfo.first_name} {basicUserInfo.last_name}
                </Header>

                <Greytext>
                  {isCurrentUser() && currentUser.role === 'admin' ? (
                      <SubHeader isMobile={isMobile}>Admin Profile</SubHeader>
                  ) : (
                      <SubHeader isMobile={isMobile}>Student Profile</SubHeader>
                  )}
                </Greytext>
              </FlexGrow>
              {!isCurrentUser()
                  && role
                  && role === 'admin'
                  && userId
                  && userId !== '' && (
                      <div style={{marginLeft: 'auto'}}>
                        <RightAlign>
                          <Button
                              status={Status.primary}
                              backgroundColor="#CB092B"
                              as="a"
                              onClick={() => viewStudentBehalf(basicUserInfo)}>
                            <Icon name="eye" mr={0.5}/>
                            {isMobile ? 'Browse as' : 'Browse Cube as'}
                            <StyledText style={{paddingLeft: _theme.spacing.xs}}>
                              {basicUserInfo.first_name}
                            </StyledText>
                          </Button>
                        </RightAlign>
                      </div>
                  )}
            </FlexBox>
          </Col>
        </Row>
        {/* <UserInfo></UserInfo> */}
        {!loading ? (
            <>
              <TabGroup>
                <TabItem active={tab === 0} onClick={() => setTab(0)}>
                  {isCurrentUser() && currentUser.role === 'admin' ? (
                      <>Admin Information</>
                  ) : (
                      <>Student Information</>
                  )}
                </TabItem>
                <TabItem active={tab === 1} onClick={() => setTab(1)}>
                  Activity Log
                </TabItem>
                <TabItem active={tab === 2} onClick={() => setTab(2)}>
                  Financial Details
                </TabItem>
              </TabGroup>
              {tab === 0 && (
                  <>
                    <Row>
                      <Col>
                        <BasicUserInfo
                            defViewMode={'view'}
                            userType={role}
                            userInfo={basicUserInfo}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <PersonalInfo
                            defViewMode={'view'}
                            userType={role}
                            userInfo={personalInfo}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <LocalAddress
                            defViewMode={'view'}
                            userType={role}
                            userInfo={localAddress}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <HomeAddress
                            defViewMode={'view'}
                            userType={role}
                            userInfo={homeAddress}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <FirstEmergencyContact
                            defViewMode={'view'}
                            userType={role}
                            userInfo={firstEmergencyContact}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <SecondEmergencyContact
                            defViewMode={'view'}
                            userType={role}
                            userInfo={secondEmergencyContact}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                        <StudentInfo
                            defViewMode={'view'}
                            userType={role}
                            userInfo={studentDetail}
                            information_confirm={verified}
                            reloadParent={fetchUserInfo.bind(this)}
                        />
                      </Col>
                    </Row>
                  </>
              )}
              {tab === 1 && (
                  <>
                    <Row>
                      <Col>
                        <Card>
                          <FlexBox
                              alignItems="flex-start"
                              style={{marginBottom: _theme.spacing.x2}}>
                            <FlexGrow>
                              <h3>Activity Log</h3>
                            </FlexGrow>
                          </FlexBox>
                          <Table hoverable>
                            <thead>
                            {!isMobile && (
                                <tr>
                                  <th
                                      style={{
                                        width: '20%',
                                        fontSize: '14px',
                                        padding: '8px',
                                      }}>
                                    Timestamp
                                  </th>
                                  <th
                                      style={{
                                        width: '55%',
                                        fontSize: '14px',
                                        padding: '8px',
                                      }}>
                                    Activity
                                  </th>
                                  <th
                                      style={{
                                        width: '25%',
                                        fontSize: '14px',
                                        padding: '8px',
                                      }}>
                                    Done by
                                  </th>
                                </tr>
                            )}
                            </thead>
                            <tbody>
                            {!loading ? (
                                <>
                                  {activityInfo.map((data: any, index: number) => (
                                      <ActivityLogItem item={data}></ActivityLogItem>
                                  ))}
                                </>
                            ) : (
                                <NullStateTR>
                                  <Icon mr={1} name="spinner" spin/>
                                  Loading...
                                </NullStateTR>
                            )}
                            </tbody>
                          </Table>
                        </Card>
                      </Col>
                    </Row>
                  </>
              )}

              {tab === 2 && showFinanceData && (
                  <>

                    <FinancialDataItem item={financialData}></FinancialDataItem>


                  </>
              )}
            </>
        ) : (
            <>
              <Icon mr={1} name="spinner" spin/>
              Loading...
            </>
        )}
      </>
  );
}

type ActivityLogProps = {
  item: ActivityInfo;
};

const ActivityLogItem = ({item}: ActivityLogProps) => {
  const formattedDate = new Date(item.created_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formattedTime = new Date(item.created_at).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return (

      <>
        <tr>
          <td>
            <StyledText style={{fontSize: '14px'}}>
              <StyledText status={TextStatus.muted}>
                {formattedDate} {formattedTime}
              </StyledText>
            </StyledText>
          </td>
          <td>
            <StyledText style={{fontSize: '14px'}}>
              {
                item?.activity_type == 'ADD' && (
                    <>Enrolled in course {item?.course.CourseName}</>
                  )
              }
              {
                  item?.activity_type == 'DROP' && (
                      <>Dropped course {item?.course.CourseName}</>
                  )
              }

              {
                  item?.activity_type == 'WITHDRAW' && (
                      <>Withdrew from course {item?.course.CourseName}</>
                  )
              }

            </StyledText>
          </td>
          <td>
            <StyledText
                style={{fontSize: '14px'}}
                status={TextStatus.muted}>
              {item?.token_role === 'admin' && (
                  <>Omega Admin</>
              )}
              {item?.token_role !== 'admin' && (
                  <>{item?.user.FirstName} {item?.user.LastName }</>
              )}
            </StyledText>
          </td>
        </tr>
      </>
  )
}


type FinancialDataProps = {
  item: FinancialInfo | undefined;
};

const FinancialDataItem = ({item}: FinancialDataProps) => {
  return (
      <>
        Name:  {item?.name}
        Total: {item?.total_cost}
        Balance: {item?.account_balance_accrued} <br/>
        {JSON.stringify(item)}
      </>
  )
}

export default PersonalInfoPage;
