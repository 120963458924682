/* eslint-disable max-len */
import { useMemo, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { useScreenClass } from "react-grid-system";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { Col, Row } from "../../components/StyledGridSystem";
import QuickAccessIcon, {
  QuickAccessContainer,
} from "../../components/QuickAccessIcon";
import _theme from "../../styles/theme";
import FlexBox from "../../components/FlexBox";
import { CourseContent, EmptyCourseContent } from "../../filter/FilterValue";
import EnrollCourse from "../../components/EnrollCourse";
import { StudentContext } from "../../context/StudentBehalfContext";
import SnackBar from "../../components/SnackBar";

interface report {
  report: any;
  term: string;
}

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Greytext = styled.div`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
`;

function MyCoursesPage() {
  const test = [
    { name: "Courses", link: "/courses/" },
    { name: "My Courses", link: "/courses/history" },
  ];

  const screenClass = useScreenClass();
  const isMobile = useMemo(() => ["xs", "sm"].includes(screenClass), [
    screenClass,
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currCourse, setCurrCourse] = useState<report>({
    report: [],
    term: "",
  });
  const [stages, setStages] = useState({
    addend: false,
    dropnoW: false,
    dropwithW: false,
  });
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [currentTerms, setCurrentTerms] = useState([
    {
      id: 0,
      name: "",
    },
  ]);
  const { student } = useContext(StudentContext);
  const [studentProgram, setStudentProgram] = useState<string>("");
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(
    EmptyCourseContent
  );
  // const handleDropCourse = (regId: string) => {
  //   // alert(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
  //   axios.post(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
  //   setIsModalOpen(false);
  //   window.location.reload(false);
  // };

  // <-------- fetch data ---------->

  useEffect(() => {
    getStudentProgram();
    fetchCurrentTerms();
  }, [student, studentProgram]);
  console.log(student);
  const getStudentProgram = async() => {
    axios
      .get(`/api/v1/students/${student.student_id}`)
      .then((res) => {
        if(res?.data?.data)
        setStudentProgram(res.data?.data?.program?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCurrentTerms = () => {
    setLoading(true);
    axios
      .get(`/api/v1/students/${student.student_id}/courses/semesters`)
      .then((res) => {
        if (res.data.data) {
          setIsNotified(true);
          setCurrentTerms(res.data.data.terms);
          if (res.data.data.terms[0]) {
            setStages({
              addend: res.data.data.terms[0].add_course_end,
              dropnoW: res.data.data.terms[0].drop_course_without_w_end,
              dropwithW: res.data.data.terms[0].drop_course_end,
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchCalendar = () => {
    axios
      .get("/api/v1/document/academic_calendar")
      .then((res) => {
        console.log(res);
        const link = document.createElement("a");
        link.href = res.data.data.document.url;
        link.setAttribute("download", res.data.data.document.file_name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          setMessage(
            error.response.data.error.message || "Failed to create competency!"
          );
          setIsErrorOpen(true);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <SnackBar
        isOpen={isErrorOpen}
        setOpen={setIsErrorOpen}
        message={message}
        type="error"
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row justify="between">
        <Col>
          <FlexBox>
            <h2>My Courses</h2>
            <div style={{ marginLeft: "auto" }}>
              <Greytext>
                <RightAlign>Current Semester</RightAlign>
              </Greytext>
              {/* {console.log(currCourse.term)} */}
              <RightAlign style={{ fontWeight: "bold" }}>
                {currentTerms &&
                  currentTerms.length > 0 &&
                  (currentTerms[0].name || "N/A")}
              </RightAlign>
            </div>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <QuickAccessContainer isMobile={isMobile}>
            <QuickAccessIcon to="/courses/courseCatalog" icon="note-text">
              Courses Catalog
            </QuickAccessIcon>
            <QuickAccessIcon
              onClick={() => fetchCalendar()}
              to="/courses/myCourses"
              icon="calendar-dates"
            >
              Academic Calendar
            </QuickAccessIcon>
            {/* <QuickAccessIcon to="/courses" icon="bookmark-add"><>Summer/<wbr/>Fall&nbsp;2021 Registration</></QuickAccessIcon> */}
            {/* <QuickAccessIcon to="/courses" icon="bookmark-add">
              <><span dangerouslySetInnerHTML={{ __html: nextsem.nsemester.replace(' ', '&nbsp;').replace('/', isMobile ? '/<wbr/>' : '/') }} /> Registration</>
            </QuickAccessIcon> */}
            <QuickAccessIcon to="/courses/history" icon="time-history">
              Enrollment History
            </QuickAccessIcon>
          </QuickAccessContainer>
        </Col>
      </Row>
      {!loading ? (
        currentTerms.map((term) => (
          <EnrollCourse
            termID={term.id}
            termName={term.name}
            program={studentProgram}
          />
        ))
      ) : (
        <></>
      )}
      {/* <EnrollCourse term='name'/> */}
    </>
  );
}
export default MyCoursesPage;
