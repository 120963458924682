import {
  useMemo, useState, useEffect, useContext, HTMLProps,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { DateLocale } from 'yup/lib/locale';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import Input from './Input';
import { StudentContext } from '../context/StudentBehalfContext';
import MinimalSelect from './MinimalSelect';
import { UserContext } from '../context/UserContext';

interface ScreenProps {
  readonly isMobile: boolean;
}
const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-cell')};
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';
/* eslint-disable camelcase */
interface User {
  user_id: string;
  student_id: string;
  enrolled_date: string;
  cmkl_id: string;
  expected_graduation_date: string;
  activity_type: {
    id: number;
    name: string;
  };
  program: {
    id: string;
    name: string;
  };
  honor: {
    id: string;
    honor_name: string;
  }
  expected_graduation_term: {
    id: string;
    name: string;
  };
}

type StudentInfoForm = {
  enrolled_date: string | undefined;
  cmkl_id: string | undefined;
  expected_graduation_date: string | undefined;
  activity_type_id: number | undefined;
  expected_graduation_term_id: string | undefined;
  program_id: string | undefined;
  honor_id: string | undefined;
  expected_graduation_term: string | undefined;
};
type StudentInfoProps = {
  defViewMode: ViewMode;
  userType: UserType;
  userInfo: User;
  information_confirm: boolean;
  reloadParent: any;
};
const StudentInfo = ({
  defViewMode,
  userType,
  userInfo,
  information_confirm,
  reloadParent,
}: StudentInfoProps) => {
  const validationSchema = Yup.object({
    enroll_date: Yup.string(),
    expected_graduation_date: Yup.string(),
    activity_type_id: Yup.number(),
    expected_graduation_term_id: Yup.string(),
    program_id: Yup.string(),
    cmkl_id: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<StudentInfoForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      enrolled_date: userInfo.enrolled_date,
      expected_graduation_date: userInfo.expected_graduation_date,
      activity_type_id: userInfo.activity_type.id,
      expected_graduation_term_id: userInfo.expected_graduation_term.id,
      program_id: userInfo.program.id,
      honor_id: userInfo.honor.id,
      cmkl_id: userInfo.cmkl_id,
    },
  });
  const { isBehalf } = useContext(StudentContext);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  // Enrolled Course Information for CourseInfoModal
  const [verified, setVerified] = useState<boolean>(false);
  const currentUser = useContext(UserContext).user;
  const [activity_types, setActivityTypes] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [terms, setTerms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [honors, setHonors] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [studentDetail, setStudentDetail] = useState({
    activity_type_id: 0,
    expected_graduation_date: '',
    cmkl_id: '',
    enrolled_date: '',
    activity_type: {
      label: '',
      value: '',
    },
    expected_graduation_term: {
      label: '',
      value: '',
    },
    program: {
      label: '',
      value: '',
    },
    honor: {
      label: '',
      value: ''
    }
  });
  const onSubmitClicked = (data: any) => {
    if (userInfo.student_id && userInfo.student_id !== '') {
      axios
        .put(`/api/v1/students/${userInfo.student_id}/information`, {
          enrolled_date,
          expected_graduation_date,
          activity_type_id: data.activity_type_id,
          expected_graduation_term: {
            id: data.expected_graduation_term_id,
          },
          cmkl_id: data.cmkl_id,
          program: {
            id: data.program_id,
          },
          honor: {
            id: data.honor_id
          }
        })
        .then(() => {
          history.replace({
            pathname: '/personalInfo',
            state: { userId: userInfo.user_id, role: userType },
          });
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    setStudentDetail({
      enrolled_date: userInfo?.enrolled_date,
      expected_graduation_date: userInfo?.expected_graduation_date,
      activity_type_id: userInfo?.activity_type.id,
      cmkl_id: userInfo?.cmkl_id,
      activity_type: {
        label: userInfo?.activity_type.name,
        value: userInfo?.activity_type.id.toString(),
      },
      expected_graduation_term: {
        label: userInfo?.expected_graduation_term.name,
        value: userInfo?.expected_graduation_term.id,
      },
      program: {
        label: userInfo?.program.name,
        value: userInfo?.program.id,
      },
      honor: {
        label: userInfo?.honor.honor_name,
        value: userInfo?.honor.id
      }
    });
    setVerified(information_confirm);
    fetchStudentStatus();
    fetchTerms();
    fetchPrograms();
    fetchHonors();
    setLoading(false);
  }, []);
  // Redirect "Registration" alert and "Add Course/(s)" button to "Add Courses" Page
  const history = useHistory();
  const fetchStudentStatus = () => {
    setLoading(true);
    const statusVals: any = [{ label: 'All', value: '' }];
    axios
      .get('/api/v1/students/status')
      .then((res) => {
        if (res.data.data.activity_types) {
          const status: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.activity_types.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              status.push(array);
            },
          );
          setActivityTypes(status);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          const term: React.SetStateAction<{ label: string; value: string }[]> = [];
          res.data.data.terms.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              term.push(array);
            },
          );
          setTerms(term);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          const program: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              program.push(array);
            },
          );
          setPrograms(program);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchHonors = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/honors')
      .then((res) => {
        if (res.data.data.honors) {
          const honor: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.honors.forEach(
            (element: { honor_name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.honor_name;
              array.value = element.id;
              honor.push(array);
            },
          );
          setHonors(honor);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  function checkEditing() {
    if (userType === 'admin') {
      return false;
    }
    if (userType === 'student' && !verified) {
      return false;
    }
    if (userType === 'student' && verified) {
      return true;
    }
    return false;
  }
  const [enrolled_date, setEnrolledDate] = useState<Dayjs | null>(
    dayjs(userInfo.enrolled_date),
  );
  const [expected_graduation_date, setGraduationDate] = useState<Dayjs | null>(
    dayjs(userInfo.expected_graduation_date),
  );
  return (
    <>
      <Card style={{ margin: '24px 0px' }}>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Student Information</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      disabled={checkEditing()}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
                {viewMode === 'edit' && !isMobile && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', margin: '0px 16px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          <Row>
            <Col md={12}>
              <Table>
                <tbody>
                  {!loading ? (
                    <>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Student ID
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.cmkl_id !== ''
                                ? studentDetail.cmkl_id
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                readOnly={currentUser.role === 'student'}
                                type="text"
                                {...register('cmkl_id')}
                                className={`form-control ${
                                  errors.cmkl_id ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="cmkl_id"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Enrolled Date
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.enrolled_date !== ''
                                ? new Date(
                                  studentDetail.enrolled_date,
                                ).toDateString()
                                : '-'}
                            </>
                          ) : (
                            <>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  openTo="year"
                                  views={['year', 'month', 'day']}
                                  label="date, month and year"
                                  value={enrolled_date}
                                  onChange={(newValue) => {
                                    setEnrolledDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              </LocalizationProvider>
                              <ErrorMessage
                                errors={errors}
                                name="enrolled_date"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Expected Graduation Date
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.expected_graduation_date !== ''
                                ? new Date(
                                  studentDetail.expected_graduation_date,
                                ).toDateString()
                                : '-'}
                            </>
                          ) : (
                            <>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  openTo="year"
                                  views={['year', 'month', 'day']}
                                  label="date, month and year"
                                  value={expected_graduation_date}
                                  onChange={(newValue) => {
                                    setGraduationDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              </LocalizationProvider>
                              <ErrorMessage
                                errors={errors}
                                name="expected_graduation_date"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Expected Graduation Term
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.expected_graduation_term.label
                              !== ''
                                ? studentDetail.expected_graduation_term.label
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                name={'expected_graduation_term_id'}
                                defaultValue={
                                  studentDetail.expected_graduation_term as any
                                }
                                render={({
                                  field: {
                                    onChange, onBlur, name, ref, value,
                                  },
                                }) => (
                                  <MinimalSelectStyled isMobile={isMobile}>
                                    <MinimalSelect
                                      name={name}
                                      defaultValue={
                                        studentDetail.expected_graduation_term
                                      }
                                      options={terms}
                                      onChange={(val: any) => onChange(val.value)
                                      }
                                    />
                                  </MinimalSelectStyled>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="expected_graduation_term"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Program
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.program.label !== ''
                                ? studentDetail.program.label
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                name={'program_id'}
                                defaultValue={studentDetail.program as any}
                                render={({
                                  field: {
                                    onChange, onBlur, name, ref, value,
                                  },
                                }) => (
                                  <MinimalSelectStyled isMobile={isMobile}>
                                    <MinimalSelect
                                      name={name}
                                      defaultValue={studentDetail.program}
                                      options={programs}
                                      onChange={(val: any) => onChange(val.value)
                                      }
                                    />
                                  </MinimalSelectStyled>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="activity_type_id"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Honor
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.honor.label !== ''
                                ? studentDetail.honor.label
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                name={'honor_id'}
                                defaultValue={studentDetail.honor as any}
                                render={({
                                  field: {
                                    onChange, onBlur, name, ref, value,
                                  },
                                }) => (
                                  <MinimalSelectStyled isMobile={isMobile}>
                                    <MinimalSelect
                                      name={name}
                                      defaultValue={studentDetail.honor}
                                      options={honors}
                                      onChange={(val: any) => onChange(val.value)
                                      }
                                    />
                                  </MinimalSelectStyled>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="activity_type_id"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Status
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {studentDetail.activity_type.label !== ''
                                ? studentDetail.activity_type.label
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                name={'activity_type_id'}
                                defaultValue={
                                  studentDetail.activity_type as any
                                }
                                render={({
                                  field: {
                                    onChange, onBlur, name, ref, value,
                                  },
                                }) => (
                                  <MinimalSelectStyled isMobile={isMobile}>
                                    <MinimalSelect
                                      name={name}
                                      defaultValue={studentDetail.activity_type}
                                      options={activity_types}
                                      onChange={(val: any) => onChange(val.value)
                                      }
                                    />
                                  </MinimalSelectStyled>
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="activity_type_id"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                    </>
                  ) : (
                    <NullStateTR>
                      <Icon mr={1} name="spinner" spin />
                      Loading...
                    </NullStateTR>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {viewMode === 'edit' && isMobile && (
            <FlexBox
              direction={'row'}
              justify="space-between"
              alignItems="center">
              <FlexGrow>
                <Button
                  onClick={() => {
                    setViewMode('view');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '0px 5px', border: 'none' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
              </FlexGrow>
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '0px 10px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          )}
        </form>
      </Card>
    </>
  );
};

export default StudentInfo;
